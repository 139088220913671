/*import Form from "./forms/forms";
const form = document.querySelector("[data-js-modul-form]");

if (form) {
    const formModul = new Form(form);

    formModul.init();
} else {
    console.log("NO FORM");
}*/

const form = document.querySelector("[data-js-modul-form]");
if (!!form) {
    import("./forms/forms").then(({default: module}) => {
        const formModule = new module(form);
        formModule.init();
    });
}

const navTrigger = document.querySelector("[data-js-module-nav-trigger]");
if (!!navTrigger) {
    import("./nav-trigger/nav-trigger").then(({default: module}) => {
        const navTriggerModule = new module(navTrigger);
        navTriggerModule.init();
    });
}

const formUpload = document.querySelector("[data-js-modul-form-upload]");
if (!!formUpload) {
    import("./form-upload/form-upload").then(({default: module}) => {
        const formUploadModul = new module(formUpload);
        formUploadModul.init();
    });
}

/*
import FormUpload from "./form-upload/form-upload";

const formUpload = document.querySelector("[data-js-modul-form-upload]");

if (formUpload) {
    const formUploadModul = new FormUpload(formUpload);
    formUploadModul.init();
}*/

